<template>
  <div>
    <van-cell>
      <template slot="title">
        <span style="color: #57c4b7"
          >*温馨提示：纸质lis报告请就近到自助打印工作站进行打印，微信医院仅供查询</span
        >
      </template>
    </van-cell>
    <van-cell-group style="margin: 2%">
      <van-cell title="报告名称">{{ list.reportName }}</van-cell>
      <van-cell title="样本ID">{{ list.reportID }}</van-cell>
      <van-cell title="病历号">{{ list.patientID }}</van-cell>
      <!-- <van-cell title="检验医生">{{list.inPatientId}}</van-cell> -->
      <van-cell title="患者姓名">{{ list.patientName }}</van-cell>
      <van-cell title="检验时间">{{ list.checkedDateTime }}</van-cell>
      <van-cell title="确认时间">{{ list.confirmDataTime }}</van-cell>
      <van-cell title="确认医生姓名">{{ list.checkedDoctName }}</van-cell>
      <van-cell title="审核医生姓名">{{ list.confirmDoctName }}</van-cell>
      <van-cell title="报告类型">{{ list.visitType }}</van-cell>
      <!-- <van-cell title="报告类型" v-else>住院</van-cell> -->
      <!-- <van-cell title="挂号流水号">{{list.regNo}}</van-cell> -->
      <!-- <van-cell title="住院流水号">{{list.inpatientSeriNo}}</van-cell> -->
      <van-cell title="取样时间">{{ list.testTime }}</van-cell>
    </van-cell-group>
    <van-cell>
      <template slot="title">
        <span style="color: red">检验详细信息</span>
      </template>
      <template slot="default">
        <span style="color: red">结果</span>
      </template>
    </van-cell>
    <van-cell-group v-for="Ditem of lists" :key="Ditem + 'RepLI'">
      <van-cell
        :title="Ditem.itemName"
        :label="'参考值：' + Ditem.itemRange + Ditem.itemUnit"
      >
        <template slot="default">
          <span style="color: #57c4b7">
            {{ Ditem.itemValue }}{{ Ditem.itemUnit }}
          </span>
        </template>
      </van-cell>
    </van-cell-group>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>@拓实银医</span>
    </div>
  </div>
</template>
<script>
import store from "../../store";
import ajax from "../../lib/ajax";
import { Toast } from "vant";
export default {
  data() {
    return {
      list: [],
      lists: [],
    };
  },
  created() {
    this.onload();
  },
  methods: {
    async onload() {
      // let checkId = window.sessionStorage.getItem("checkId");
      let RepId = store.getters["store/reportId"];
      // console.log(checkId);
      let outpatientId = store.getters["store/patientid"];
      console.log(outpatientId);
      // console.log(RepId.hasOwnProperty(checkId));
      // RepId.forEach((item)=> {
      //   console.log(item.toString());
      // });
      // var map = new Map();
      // map.set(RepId,hisItemID);
      // let idcard = store.getters["store/idcard"];
      await ajax
        .post("/Api/ReportService/GetLisReportDetail", {
          userID: outpatientId,
          reportId: RepId,
          machineName: "wechat",
        })
        .then((res) => {
          let info = res.data.result[0];
          console.log(info.lisReport);
          if (info.lisReport === null) {
            this.$router
              .replace({
                path: "/RepPI",
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.list = info;
            this.lists = info.list;
          }
        })
        .catch((err) => {
          Toast("发生未知错误" + err);
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>
